import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setError("Veuillez entrer votre email.");
            return;
        }

        setLoading(true);
        setError("");
        setSuccess("");

        try {
            const response = await fetch(
                "https://phplaravel-1295430-5113567.cloudwaysapps.com/api/forgot-password",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                }
            );

            const data = await response.json();

            if (response.ok) {
                setSuccess("A reset email has been sent.");
            } else {
                setError(data.error || "An error has occurred.");
            }
        } catch (err) {
            setError("Unable to send email. Check your connection.");
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "#f8f9fa",
        },
        card: {
            width: "100%",
            maxWidth: "400px",
            padding: "30px",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            textAlign: "center",
        },
        title: {
            fontSize: "24px",
            marginBottom: "20px",
            color: "#4c4c4c",
        },
        inputGroup: {
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f1f3f6",
            padding: "10px",
        },
        inputIcon: {
            marginRight: "10px",
            color: "#7973fe",
        },
        input: {
            flex: 1,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: "16px",
            color: "#4c4c4c",
        },
        button: {
            width: "100%",
            padding: "10px",
            borderRadius: "24px",
            background: "linear-gradient(90deg, #ac2ff1, #7973fe)",
            color: "#fff",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
        },
        message: {
            fontSize: "14px",
            color: "#7973fe",
            marginTop: "10px",
        },
        error: {
            color: "red",
            fontSize: "14px",
            marginBottom: "10px",
        },
        success: {
            color: "green",
            fontSize: "14px",
            marginBottom: "10px",
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>Forget Password</h2>
                <form onSubmit={handleSubmit}>
                    <div style={styles.inputGroup}>
                        <FaEnvelope style={styles.inputIcon} />
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                        />
                    </div>
                    {error && <p style={styles.error}>{error}</p>}
                    {success && <p style={styles.success}>{success}</p>}
                    <button type="submit" style={styles.button} disabled={loading}>
                        {loading ? "Sending..." : "Send Reset Link"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgetPassword;
