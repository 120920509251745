import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams(); // Récupère le token depuis l'URL

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            setError("Veuillez remplir tous les champs.");
            return;
        }

        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas.");
            return;
        }

        setLoading(true);
        setError("");
        setSuccess("");

        try {
            const response = await fetch(
                `https://phplaravel-1295430-5113567.cloudwaysapps.com/api/reset-password/${token}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ password }),
                }
            );

            const data = await response.json();

            if (response.ok) {
                setSuccess("Your password has been successfully reset.");
                setTimeout(() => navigate("/login"), 3000); // Redirection après 3 secondes
            } else {
                setError(data.error || "An error has occurred.");
            }
        } catch (err) {
            setError("Unable to reset password. Check your login.");
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "#f8f9fa",
        },
        card: {
            width: "100%",
            maxWidth: "400px",
            padding: "30px",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            textAlign: "center",
        },
        title: {
            fontSize: "24px",
            marginBottom: "20px",
            color: "#4c4c4c",
        },
        input: {
            width: "100%",
            padding: "10px",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f1f3f6",
            fontSize: "16px",
            color: "#4c4c4c",
        },
        button: {
            width: "100%",
            padding: "10px",
            borderRadius: "24px",
            background: "linear-gradient(90deg, #ac2ff1, #7973fe)",
            color: "#fff",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
        },
        error: {
            color: "red",
            fontSize: "14px",
            marginBottom: "10px",
        },
        success: {
            color: "green",
            fontSize: "14px",
            marginBottom: "10px",
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <input
                        type="password"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={styles.input}
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={styles.input}
                    />
                    {error && <p style={styles.error}>{error}</p>}
                    {success && <p style={styles.success}>{success}</p>}
                    <button type="submit" style={styles.button} disabled={loading}>
                        {loading ? "Resetting..." : "Reset Password"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
