import classes from './Chat.module.scss';
import natasha from '../../assets/imags/natasha.png';
import React, { useEffect, useState, useRef } from "react";
import { ImpulseSpinner } from "react-spinners-kit";
import axios from 'axios';
import { createGlobalStyle } from 'styled-components';


const Chat = ({ chatToken }) => {
    const ref = useRef(null);
    const API_URL = process.env.REACT_APP_API_URL; // Symfony backend URL
    const agent_name = "Natasha"; // Replace with dynamic value if needed

    const [chats, setChats] = useState([]); // Messages affichés
    const [isLoading, setIsLoading] = useState(true); // Chargement initial des messages
    const [msg, setMsg] = useState(''); // Message saisi par l'utilisateur
    const [isMsgEmpty, setIsMsgEmpty] = useState(true); // Indicateur de message vide
    const [msgSentError, setMsgSentError] = useState(false); // Erreur lors de l'envoi du message
    const [isMsgSending, setIsMsgSending] = useState(false); // Indique si un message est en cours d'envoi
    const [sessionId, setSessionId] = useState(null); // ID de la session

    // Fonction pour formater les messages contenant des liens
    const formatMessage = (message) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return message.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="font-size: 16px;color: blue; text-decoration: underline;">${url}</a>`;
        });
    };

    // Initialiser une nouvelle session si nécessaire
    const initializeSession = async () => {
        let existingSessionId = localStorage.getItem('chat_session_id');

        if (existingSessionId) {
            console.log("Existing session found. Fetching chat history...");
            setSessionId(existingSessionId);
            fetchChatHistory(existingSessionId); // Load chat history for existing session
        } else {
            console.log("No session found. Creating a new one...");
            try {
                const response = await axios.post(`${API_URL}/chat/session`, {
                    token: chatToken,
                });

                const newSessionId = response.data.sessionId;
                const initialMessage = {
                    role: 'ai',
                    message: response.data.initialMessage,
                    createdAt: new Date().toISOString(),
                };

                localStorage.setItem('chat_session_id', newSessionId);
                setSessionId(newSessionId);
                setChats([initialMessage]);
            } catch (error) {
                console.error("Error initializing session:", error);
            }
        }
    };

    // Charger l'historique des messages
    const fetchChatHistory = async () => {
        try {
            const response = await axios.get(`${API_URL}/chat/history`, {
                params: { sessionId },
            });

            if (Array.isArray(response.data) && response.data.length > 0) {
                setChats(response.data);
            } else {
                console.error("No chat history found, inserting initial message...");
                const initialMessage = {
                    role: 'ai',
                    message: "Hi, I'm Natasha, your personal astrologer! ✨🔮 Want to discover what the stars 🌟 have to say about you today? 🌙",
                    createdAt: new Date().toISOString(),
                };
                setChats([initialMessage]);
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
            setChats([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Gérer l'envoi d'un message
    const handleMsgSend = async () => {
        setMsgSentError(false);

        if (!isMsgEmpty && msg.trim() !== '') {
            const newMessage = {
                role: 'user',
                message: msg,
                createdAt: new Date().toISOString(),
            };

            setChats([...chats, newMessage]);
            setMsg('');
            setIsMsgSending(true);

            try {
                const response = await axios.post(`${API_URL}/chat/send`, {
                    sessionId,
                    message: msg,
                    token: chatToken,
                });

                const aiMessage = {
                    role: 'ai',
                    message: response.data.response,
                    createdAt: new Date().toISOString(),
                };
                setChats((prevChats) => [...prevChats, aiMessage]);
            } catch (error) {
                console.error("Error sending message:", error);
                setMsgSentError(true);
            } finally {
                setIsMsgSending(false);
            }
        }
    };

    const handleMsgInput = (e) => {
        const input_msg = e.target.value;
        setMsg(input_msg);
        setIsMsgEmpty(input_msg.trim() === '');
    };

    useEffect(() => {
        console.log("Chat Token Received:", chatToken);
        const initialize = async () => {
            await initializeSession();
        };
        initialize();
    }, []);

    useEffect(() => {
        if (sessionId) {
            fetchChatHistory();
        }
    }, [sessionId]);

    return (

        <div className={classes.chat_div}>
            <div className={classes.chat_div_main}>
                <div className={classes.chat_div_main_left}>
                    <img src={natasha} alt="Natasha" />
                </div>
                <div className={classes.chat_div_main_right}>
                    <div className={classes.chat_div_chat}>
                        <div className={classes.chat_div_chat_scroll}>
                            <div ref={ref} className={classes.chat_div_chat_scroll_100}>
                                {chats.map((item, index) => (
                                    <div key={index}>
                                        {item.role === 'ai' ? (
                                            <div className={classes.each_chat_div_parent_left}>
                                                <div className={classes.each_chat_div_left}>
                                                    <div
                                                        className={classes.chat_div_chat_msg}
                                                        dangerouslySetInnerHTML={{ __html: formatMessage(item.message) }}
                                                    ></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={classes.each_chat_div_parent_right}>
                                                <div className={`${classes.each_chat_div_right}`}>
                                                    <div className={classes.chat_div_chat_msg}>
                                                        {item.message}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {isLoading && (
                                    <div className={classes.text_spinner}>
                                        <ImpulseSpinner size={30} frontColor="#ffffff80" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={classes.input_div}>
                            <input
                                type="text"
                                placeholder={`Send message to ${agent_name}!`}
                                value={msg}
                                onKeyDown={(event) => event.key === 'Enter' && handleMsgSend()}
                                onChange={handleMsgInput}
                            />
                            <button onClick={handleMsgSend}>
                                {isMsgSending ? <ImpulseSpinner size={30} frontColor="#fff" /> : "SEND"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
