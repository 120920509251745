import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaLock } from "react-icons/fa";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();


    // Redirect to session page if token exists
    useEffect(() => {

        const token = localStorage.getItem("authToken");
        const session = localStorage.getItem("session");
        if (token && session) {
            navigate(`/${session}`);
        }
    }, [navigate]);



    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError("Veuillez remplir tous les champs.");
            return;
        }

        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        try {
            const response = await fetch("https://phplaravel-1295430-5113567.cloudwaysapps.com/api/login_check", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem("authToken", data.token);
                localStorage.setItem("session", data.session);
                navigate(`/${data.session}`);
            } else {
                setError(data.error || "Nom d'utilisateur ou mot de passe incorrect.");
            }
        } catch (err) {
            setError("Impossible de se connecter. Vérifiez votre connexion.");
        }
    };

    const styles = {
        container: {
            display: "grid",
            gridTemplateColumns: "1.5fr 1fr", // La colonne gauche est 1.5 fois plus grande que la droite
            height: "100vh",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "#f8f9fa",
        },
        leftColumn: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "#fff",
        },
        formWrapper: {
            width: "100%",
            maxWidth: "400px",
        },
        rightColumn: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f8f9fa",
        },
        loginCard: {
            width: "100%",
            padding: "30px",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
        },
        logo: {
            width: "80px",
            marginBottom: "20px",
        },
        inputGroup: {
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f1f3f6",
            padding: "10px",
        },
        inputIcon: {
            marginRight: "10px",
            color: "#7973fe",
        },
        input: {
            flex: 1,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: "16px",
            color: "#4c4c4c",
        },
        button: {
            width: "100%",
            padding: "10px",
            borderRadius: "24px",
            background: "linear-gradient(90deg, #ac2ff1, #7973fe)",
            color: "#fff",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            marginBottom: "20px",
        },
        divider: {
            display: "flex",
            alignItems: "center",
            margin: "20px 0",
        },
        line: {
            flex: 1,
            height: "1px",
            backgroundColor: "#ddd",
        },
        orText: {
            margin: "0 10px",
            color: "#aaa",
        },
        signUpButton: {
            width: "100%",
            padding: "10px",
            borderRadius: "24px",
            background: "transparent",
            color: "#7973fe",
            border: "1px solid #7973fe",
            cursor: "pointer",
            fontSize: "16px",
        },
        rightImage: {
            width: "100%",
            maxWidth: "400px",
            height: "auto",
            objectFit: "contain",
        },
    };

    return (
        <div style={styles.container}>
            {/* Left Column */}
            <div style={styles.leftColumn}>
                <div style={styles.formWrapper}>
                    <div style={styles.loginCard}>
                        <img
                            src="/astro.png"
                            alt="Logo"
                            style={styles.logo}
                        />
                        <h3>Login</h3>
                        <form onSubmit={handleLogin}>
                            <div style={styles.inputGroup}>
                                <FaEnvelope style={styles.inputIcon} />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.inputGroup}>
                                <FaLock style={styles.inputIcon} />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={styles.input}
                                />
                            </div>
                            {error && <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>}
                            <button type="submit" style={styles.button}>
                                Login
                            </button>
                        </form>
                        <a href="/forgot-password" style={{ color: "#7973fe", textDecoration: "underline" }}>
                            Forgot Password?
                        </a>
                        <div style={styles.divider}>
                            <div style={styles.line}></div>

                            <div style={styles.line}></div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div style={styles.rightColumn}>
                <img
                    src="/login-right-image.png"
                    alt="Illustration"
                    style={styles.rightImage}
                />
            </div>
        </div>
    );
};

export default Login;
