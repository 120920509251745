import { useNavigate } from "react-router-dom"; // Importer useNavigate
import classes from './Navbar.module.scss';

const Navbar = () => {
    const navigate = useNavigate(); // Initialiser navigate

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        navigate("/"); // Redirige vers le login
    };

    const logoutButtonStyle = {
        padding: "10px 20px",
        background: "rgba(0, 255, 30, 0.7058823529)",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "bold",
        transition: "background 0.3s ease",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    };

    const logoutButtonHoverStyle = {
        background: "linear-gradient(135deg, #48A9A6, #48A9A6)",
    };

    return (
        <div className={classes.container}>

                <div className={classes.navbar_main_left}>
                    <img src="/astro.png" alt="Logo" style={{ width: "50px" }} className={classes.logo} />
                    <div>Prompt <small>1.0</small></div>
                </div>
                <div className={classes.navbar_main_right}>
                    <button
                        onClick={handleLogout}
                        className={classes.logout_button}
                        style={logoutButtonStyle}
                        onMouseOver={(e) => {
                            e.target.style.background = logoutButtonHoverStyle.background;
                        }}
                        onMouseOut={(e) => {
                            e.target.style.background = logoutButtonStyle.background;
                        }}
                    >
                        Logout
                    </button>
                </div>

        </div>
    );
};

export default Navbar;
