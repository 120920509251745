import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const TokenLogin = () => {
    const { session_id, token } = useParams(); // Récupère session_id et token depuis l'URL
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await fetch(
                    `https://phplaravel-1295430-5113567.cloudwaysapps.com/api/verify-token`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ session_id, token }),
                    }
                );

                const data = await response.json();

                if (response.ok && data.valid) {
                    // Si le token est valide, connectez l'utilisateur
                    localStorage.setItem("authToken", token);
                    localStorage.setItem("session", session_id);

                    // Redirection vers le tableau de bord
                    navigate("/"+session_id);
                } else {
                    // Si le token n'est pas valide, rediriger vers la page de connexion
                    alert("Invalid token. Redirecting to login.");
                    navigate("/login");
                }
            } catch (err) {
                console.error("Error verifying token:", err);
                alert("An error occurred. Redirecting to login.");
                navigate("/login");
            }
        };

        verifyToken();
    }, [session_id, token, navigate]);

    return (
        <div>
            <p>Verifying token, please wait...</p>
        </div>
    );
};

export default TokenLogin;
