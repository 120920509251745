// pages
import Chat from '../Chat/Chat';
import { createGlobalStyle } from 'styled-components';
// components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile";

// modules/packages/libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

// local modules
import useWindowSize from '../../assets/hooks/useWindowSize.js';
import { useSelector } from "react-redux";

import { GooSpinner } from "react-spinners-kit";

import { encryptData, decryptData } from '../../utils/helper_functions';

const Main = () => {
    const { token } = useParams(); // Récupère le token depuis la route
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const aes_salt_key = process.env.REACT_APP_AES_SALT;
    let { width } = useWindowSize();
    const showNavBarMobile = useSelector(state => state.ui.showNavBarMobile);

    const temp_uuid = localStorage.getItem('prompt_uuid', null);
    const prompt_uuid = decryptData(temp_uuid, aes_salt_key);
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key);

    // states
    const [showLoading, setShowLoading] = useState(true);
    const [chatroomKey, setChatroomKey] = useState(token); // Utilise le token comme chatroomKey initial

    // Create anonymous user
    const createAnonymousUser = async () => {
        try {
            console.log("Authenticating user...");
            const res = await fetch(`${API_URL}/api/v1/auth`, { method: 'GET' });
            const data = await res.json();

            if (res.status === 200) {
                // Store user details in localStorage
                localStorage.setItem('prompt_uuid', encryptData(data['uuid'], aes_salt_key));
                localStorage.setItem('prompt_access_token', encryptData(data['access_token'], aes_salt_key));
                localStorage.setItem('prompt_chat_room', data['chat_token']);

                setChatroomKey(data['chat_token']); // Met à jour le chatroomKey
                navigate(`/${data['chat_token']}`);
            } else {
                console.error("Failed to create anonymous user:", data);
            }
        } catch (error) {
            console.error("Error creating anonymous user:", error);
        } finally {
            setShowLoading(false);
        }
    };




    // Get chatroom key for anonymous user
    const getChatroomKey = async (uuid, access_token) => {
        try {
            console.log("Getting chatroom key...");
            const res = await fetch(`${API_URL}/api/v1/chattokens`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                    'uuid': uuid
                }
            });

            const data = await res.json();

            if (res.status === 200) {
                localStorage.setItem('prompt_chat_room', data['chat_token']);
                setChatroomKey(data['chat_token']); // Met à jour le chatroomKey
            } else {
                console.error("Failed to get chatroom key:", data);
            }
        } catch (error) {
            console.error("Error getting chatroom key:", error);
        } finally {
            setShowLoading(false);
        }
    };

    // Initialization logic
    useEffect(() => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            navigate("/"); // Redirige vers le login si le token est manquant
        }
        const initialization = async () => {
            console.log("Initializing...");

            if (!prompt_uuid) {
                await createAnonymousUser();
            } else if (!chatroomKey) {
                await getChatroomKey(prompt_uuid, prompt_access_token);
            }
        };

        initialization();
    }, [chatroomKey], [navigate]); // Re-exécute lorsque chatroomKey change

    const GlobalStyle = createGlobalStyle`
        /* league spartan font-face */
        @font-face {
            font-family: "League Spartan";
            src: url("./assets/fonts/LeagueSpartan-Regular.ttf");
        }
        @font-face {
            font-family: "League Spartan Bold";
            src: url("./assets/fonts/LeagueSpartan-Bold.ttf");
        }
        @font-face {
            font-family: "League Spartan Thin";
            src: url("./assets/fonts/LeagueSpartan-Light.ttf");
        }
        /* space mono font-face */
        @font-face {
            font-family: "Space Mono";
            src: url("./assets/fonts/SpaceMono-Regular.ttf");
        }
        @font-face {
            font-family: "Space Mono Bold";
            src: url("./assets/fonts/SpaceMono-Bold.ttf");
        }
        /* Quicksand font-face */
        @font-face {
            font-family: "Quicksand";
            src: url("./assets/fonts/Quicksand-Medium.ttf");
        }
        @font-face {
            font-family: "Quicksand Bold";
            src: url("./assets/fonts/Quicksand-Bold.ttf");
        }
        @font-face {
            font-family: "Quicksand Thin";
            src: url("./assets/fonts/Quicksand-Light.ttf");
        }



        *{
            /* border: 1px dotted rgb(175, 175, 175); */

            margin: 0;
            padding: 0;

            font-family: 'League Spartan Bold';
            font-size: 0.9rem;
            color: #ffffff;
        }

        html {
            overflow: hidden;
            box-sizing: border-box;
        }


        body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
`;

    return (
        <>
            {showLoading ? (
                <div>
                    <GooSpinner />
                </div>
            ) : (
                <div>
                    {width > 500 && <NavBar />}
                    {showNavBarMobile && <NavBarMobile />}
                    <GlobalStyle />

                    <Chat chatToken={chatroomKey} />
                </div>
            )}
        </>
    );
};

export default Main;
